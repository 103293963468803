import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/seed/source/services/main-www/src/components/mdx-docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`API Reference`}</h1>
    <p>{`The Gateway API is organized around REST and returns JSON-encoded responses.`}</p>
    <h2>{`Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#authentication"
        }}>{`Authentication`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#accounts"
        }}>{`Accounts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-accounts"
        }}>{`List accounts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#show-an-account"
        }}>{`Show an account`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#show-account-balance"
        }}>{`Show account balance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-charge-token"
        }}>{`Create charge token`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#charge-money"
        }}>{`Charge money`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#show-payment-status"
        }}>{`Show payment status`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#state-code-errors"
            }}>{`State code errors`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-refill-token"
        }}>{`Create refill token`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#refill"
        }}>{`Refill`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#refund"
        }}>{`Refund`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-transactions"
        }}>{`List transactions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-webhook-endpoint"
        }}>{`Create webhook-endpoint`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#update-webhook-endpoint"
        }}>{`Update webhook-endpoint`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-webhook-endpoints"
        }}>{`List webhook-endpoints`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#delete-webhook-endpoint"
        }}>{`Delete webhook-endpoint`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#webhook-event"
        }}>{`Webhook Event`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#check-the-webhook-signatures"
        }}>{`Check the webhook signatures`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#checkout"
        }}>{`Checkout`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#create-a-checkout-session-server-side"
            }}>{`Create a Checkout Session (Server-side)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#redirect-to-checkout-client-side"
            }}>{`Redirect to Checkout (Client-side)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#show-checkout-session-payment-status-server-side"
            }}>{`Show Checkout Session payment status (Server-side)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3d-secure-processing-special-use-cases"
        }}>{`3D Secure Processing (Special use cases)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-rates"
        }}>{`List rates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#get-current-time"
        }}>{`Get current time`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#testing"
        }}>{`Testing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#nodejs-client"
        }}>{`Node.js Client`}</a></li>
    </ul>
    <h2>{`Authentication`}</h2>
    <p>{`All API key requests must be signed and contain the authorization header:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Authorization: NP-ACCESS-TOKEN <token>`}</inlineCode>{` (see below)`}</li>
    </ul>
    <p>{`All request bodies should have content type application/json and be valid JSON.`}</p>
    <p><inlineCode parentName="p">{`const token = version + '.' + timestamp + '.' + apiKey + '.' + signature`}</inlineCode></p>
    <p>{`The `}<inlineCode parentName="p">{`signature`}</inlineCode>{` is generated by creating a sha256 HMAC using the secret key on the prehash string `}<inlineCode parentName="p">{`timestamp + method + requestPath + query + body`}</inlineCode>{` (where + represents string concatenation).`}</p>
    <p>{`The `}<inlineCode parentName="p">{`body`}</inlineCode>{` is the request body string or omitted if there is no request body (typically for GET requests).`}</p>
    <p>{`The `}<inlineCode parentName="p">{`method`}</inlineCode>{` should be UPPER CASE.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`timestamp`}</inlineCode>{` MUST be number of seconds since `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Unix_time"
      }}>{`Unix Epoch`}</a>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`query`}</inlineCode>{` must be sorted by query parameter names and started with '?' or omitted if there is no query params in request`}</p>
    <p>{`The `}<inlineCode parentName="p">{`version`}</inlineCode>{` is string `}<inlineCode parentName="p">{`2019-12-22`}</inlineCode></p>
    <p>{`Your timestamp must be within 30 seconds of the api service time or your request will be considered expired and rejected. We recommend using the `}<a parentName="p" {...{
        "href": "#get-current-time"
      }}>{`time`}</a>{` endpoint to query for the API server time if you believe there many be time skew between your server and the API servers.`}</p>
    <p>{`Client implementation example:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#nodejs-client"
        }}>{`client.js`}</a></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const Client = require('./client')
// Set these in your ENVironment, or enter them here with the actual string
const apiKey = ''
const apiSecret = ''
const client = new Client(apiKey, apiSecret, 'https://api.example.com')
`}</code></pre>
    <p>{`Playground (Repl):`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://playground.x.example.com"
        }}>{`https://playground.x.example.com`}</a></li>
    </ul>
    <h2>{`Accounts`}</h2>
    <p>{`Account resource represents all of a user’s accounts`}</p>
    <h2>{`List accounts`}</h2>
    <p>{`Lists current user’s accounts to which the authentication method has access to.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/accounts`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts'
)

{
  "data": [
    {
      "id": "e779b555-40d8-4f5b-8274-d1cad13d4da2",
      "currency": "RUB"
    }
  ]
}
`}</code></pre>
    <h2>{`Show an account`}</h2>
    <p>{`Show current user’s account.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/accounts/:account_id`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2'
)

{
  "data": {
    "id": "e779b555-40d8-4f5b-8274-d1cad13d4da2",
    "currency": "RUB"
  }
}
`}</code></pre>
    <h2>{`Show account balance`}</h2>
    <p>{`Show current balance on the user’s account at the current moment.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/accounts/:account_id/balance`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/balance'
)

{
  "data": {
    "amount": "10000",
    "currency": "RUB"
  }
}
`}</code></pre>
    <h2>{`Create charge token`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/accounts/:account_id/charge/token`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`redirect_url`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Link to the page to which the user will get after entering 3dsec`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original amount (in smallest common currency units; Ex. kopecks)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original transaction currency. Three-letter ISO currency code, in uppercase. Must be a supported currency. The default is account currency.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`metadata`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Set of key-value pairs. Up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`hash (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge/token',
  {
    method: 'POST',
    parameters: {
      amount: '100',
      redirect_url: 'https://example.com'
    }
  }
)

{
  "data": {
    "token": "ded23543-241c-4c88-8d4a-4cd145269841",
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b"
  }
}
`}</code></pre>
    <h2>{`Charge money`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/accounts/:account_id/charge`}</inlineCode></p>
    <p>{`Charge money from card`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`token`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The token required to request this method. Life time is 15 minutes. Number of attempts - 1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card information`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`object (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.month`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card expiration month`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.year`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card expiration year`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.cvv`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card cvv`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Customer information`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`object (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.first_name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`First name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.last_name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Last name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.address`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Address line`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.city`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`City, district, suburb, town, or village`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.zip_code`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`ZIP or postal code`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.country`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Two-letter country code (`}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
            }}>{`ISO 3166-1 alpha-2`}</a>{`)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.phone`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The customer’s phone number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.email`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The customer’s email`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.ip_address`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Current customer’s ip address`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.date_of_birth`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Date of birth`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge',
  {
    method: 'POST',
    parameters: {
      token: 'ded23543-241c-4c88-8d4a-4cd145269841',
      card_info: {
        number: '4242424242424242',
        month: '12',
        year: '2025',
        cvv: '123',
        holder: 'CARD HOLDER'
      }
    }
  }
)

{
  "data": {
    "charge_link": "https://api.example.com/v2/charge/txOPdVd48URiXykjHR6cw3DRytero3Er"
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge',
  {
    method: 'POST',
    parameters: {
      token: 'ded23543-241c-4c88-8d4a-4cd145269841',
      card_info: {
        number: '4242424242424242',
        month: '12',
        year: '2025',
        cvv: '123',
        holder: 'CARD HOLDER'
      },
      customer: {
        first_name: 'First',
        last_name: 'Last',
        address: 'Street, 60',
        city: 'City',
        zip_code: '12345',
        country: 'US',
        phone: '79000000000',
        email: 'mail@example.com',
        ip_address: '127.0.0.1',
        date_of_birth: '1901-01-31',
      }
    }
  }
)

{
  "data": {
    "charge_link": "https://api.example.com/v2/charge/XmATAS52RingMRpoSo2hHHnCH8nbqfYZ"
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Response field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`charge_link`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Link to the page to which the user should be redirected to complete 3dsec flow`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Show payment status`}</h2>
    <p>{`The method can be used to request the payment status.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/accounts/:account_id/payment/:order_slug`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b'
)

{
  "data": {
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b",
    "is_completed": true,
    "status": "success",
    "type": "charge_unregistered",
    "amount": "100",
    "currency": "RUB",
    "fee": "20",
    "net": "80",
    "card_number": "424242******4242",
    "state_code": 0,
    "state_description": "No errors",
    "created_at": "2020-01-22T10:36:32.627Z",
    "account_id": "e78db2b4-cf40-4eba-b98b-d2296934cd58",
    "checkout_session_id": null,
    "payment_method_id": null,
    "original_amount": "100",
    "original_currency": "RUB"
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Response field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`order_slug`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unique identifier for the operation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`is_completed`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Operation completion status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`status`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Transaction status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Type of transaction (refill_unregistered `}{`|`}{` charge_unregistered)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge/refill amount (in kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Transaction currency that equals account currency (now only RUB)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`fee`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge/refill fee (in kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`net`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge/refill net (in kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Masked card number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`state_code`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Error code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`state_description`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Error description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`created_at`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Time at which the transaction was created. ISO 8601 datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`account_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Account id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout_session_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Checkout session id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`payment_method_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Payment method id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`original_amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge amount before conversion to the account currency (in smallest common currency units; Ex. kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`original_currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Transaction currency before conversion to the account currency`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`metadata`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`State code errors`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`state_code`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`state_description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`No errors`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unknown bank error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Insufficient funds`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Invalid card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Transaction unavailable to card holder`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Expired card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Lost card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Failure of the issuer or payment system`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card operations limited`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`19`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Eliminated card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Invalid card number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`21`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Bin not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`22`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card top-up operations not available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`23`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Invalid cvc`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Stolen card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Eliminated card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`26`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`27`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unsupported card country`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`28`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unsupported card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`29`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Failure of the issuer or payment system. Retry again`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`30`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Merchant limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`31`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Account limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`32`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Terminal limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`33`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card transaction frequency exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`34`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card daily limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`35`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card monthly limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`36`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Incorrect pin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`37`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`No payment received`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`38`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Details of another bank`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`39`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Invalid details`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`40`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Canceled by user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`41`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Canceled by system`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Statuses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`status`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`new`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`An operation request has been created`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`pending`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The operation is in process. Processing an operation takes 1-2 minutes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`hold`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Holding operation. Cash successfully frozen on customer card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Operation error. The error description will be returned in the notification or 'Show payment status' method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`expired`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The order has expired. Can happend if the operation has not been completed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`success`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Successful operation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`canceled`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The operation is canceled. The status is used for holding operations and charging from the card`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Create refill token`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/accounts/:account_id/refill/token`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original amount (in smallest common currency units; Ex. kopecks)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original transaction currency. Three-letter ISO currency code, in uppercase. Must be a supported currency. The default is account currency.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`metadata`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Set of key-value pairs. Up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`hash (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/refill/token',
  {
    method: 'POST',
    parameters: {
      amount: '100'
    }
  }
)

{
  "data": {
    "token": "4259bbfd-4297-4afd-9842-e29b22fd3786",
    "order_slug": "client-7f6442bc-9059-41ef-bb91-668ec93505ad"
  }
}
`}</code></pre>
    <h2>{`Refill`}</h2>
    <p>{`Refill card`}</p>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/accounts/:account_id/refill`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`token`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The token required to request this method. Life time is 15 minutes. Number of attempts - 1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_month`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card expiration month. 2 digits`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_year`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card expiration year. 4 digits`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/refill',
  {
    method: 'POST',
    parameters: {
      token: '4259bbfd-4297-4afd-9842-e29b22fd3786',
      card_number: "4242424242424242"
    }
  }
)

{
  "data": {
    "token": "4259bbfd-4297-4afd-9842-e29b22fd3786"
  }
}
`}</code></pre>
    <h2>{`Refund`}</h2>
    <p>{`The method allows you to issue a refund for the charge from a client's card.
After a successful return, the operation will go to the status `}<inlineCode parentName="p">{`canceled`}</inlineCode></p>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/accounts/:account_id/refund`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`order_slug`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unique identifier for the operation`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`uuid (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout_session_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Checkout session id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Either `}<inlineCode parentName="p">{`order_slug`}</inlineCode>{` or `}<inlineCode parentName="p">{`checkout_session_id`}</inlineCode>{` must be set`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/refund',
  {
    method: 'POST',
    parameters: {
      order_slug: "client-6fb24d8e-9bd6-4ef9-9695-5f31c57c7f24"
    }
  }
)

{
  "data": {
    "order_slug": "client-6fb24d8e-9bd6-4ef9-9695-5f31c57c7f24",
    "status": "success"
  }
}
`}</code></pre>
    <h2>{`List transactions`}</h2>
    <p>{`The transactions are sorted in descending order by creation date`}</p>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/accounts/:account_id/transactions`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`GET query params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`from`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Return results where created_at >= from`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`ISO 8601 datetime (query)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Return results where created_at < to`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`ISO 8601 datetime (query)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`limit`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`A limit on the number of objects to be returned. Limit can range between 0 and 100, and the default is 10.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`int (query)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`offset`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Offset`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`int (query)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/transactions'
)

{
  "data": [
    {
      // ... 'Show payment status' data
    },
    //...
  ]
}
`}</code></pre>
    <h2>{`Create webhook-endpoint`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/accounts/:account_id/webhook-endpoints`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/webhook-endpoints',
  {
    method: 'POST',
    parameters: {
      url: 'https://example.com/webhook/secret',
      event: 'order'
    }
  }
)

{
  "data": {
    "id": "f9bddc29-d5d9-4dd7-8058-bcacb63747e7",
    "url": "https://example.com/webhook/secret",
    "event": "order",
    "secret": "whsec_TkxzSEe4oe5xJhRxZ217LEXtpVvsqEyJ1Q3HzoNKiKu2quaWviS585Ex",
    "created_at": "2020-01-08T12:14:03.084Z",
    "updated_at": "2020-01-08T12:14:03.084Z"
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Response field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unique identifier for the object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`url`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The URL of the webhook endpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`event`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Event to enable for this endpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`secret`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The endpoint’s secret, used to generate webhook signatures. Only returned at creation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`created_at`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Time at which the object was created. ISO 8601 datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`updated_at`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Time at which the object was updated. ISO 8601 datetime`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Update webhook-endpoint`}</h2>
    <p><inlineCode parentName="p">{`PUT https://api.example.com/v2/accounts/:account_id/webhook-endpoints/:id`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/webhook-endpoints/f9bddc29-d5d9-4dd7-8058-bcacb63747e7',
  {
    method: 'PUT',
    parameters: {
      url: 'https://example.com/webhook2/secret2',
      event: 'order'
    }
  }
)

{
  "data": {
    "id": "f9bddc29-d5d9-4dd7-8058-bcacb63747e7",
    "url": "https://example.com/webhook2/secret2",
    "event": "order",
    "created_at": "2020-01-08T12:14:03.084Z",
    "updated_at": "2020-01-08T12:19:32.440Z"
  }
}
`}</code></pre>
    <h2>{`List webhook-endpoints`}</h2>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/accounts/:account_id/webhook-endpoints`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/webhook-endpoints'
)

{
  "data": [
    {
      "id": "f9bddc29-d5d9-4dd7-8058-bcacb63747e7",
      "url": "https://example.com/webhook2/secret2",
      "event": "order",
      "created_at": "2020-01-08T12:14:03.084Z",
      "updated_at": "2020-01-08T12:19:32.440Z"
    }
  ]
}
`}</code></pre>
    <h2>{`Delete webhook-endpoint`}</h2>
    <p><inlineCode parentName="p">{`DELETE https://api.example.com/v2/accounts/:account_id/webhook-endpoints/:id`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/webhook-endpoints/f9bddc29-d5d9-4dd7-8058-bcacb63747e7',
  {
    method: 'DELETE'
  }
)

{
  "data": {
    "id": "f9bddc29-d5d9-4dd7-8058-bcacb63747e7"
  }
}
`}</code></pre>
    <h2>{`Webhook Event`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "type": "order",
  "data": {
    // ... 'Show payment status' data
  },
  "event_at": "2020-02-01T12:14:01.052Z"
}
`}</code></pre>
    <h2>{`Check the webhook signatures`}</h2>
    <p>{`Gateway can optionally sign the webhook events it sends to your endpoints by including a signature in each event’s `}<inlineCode parentName="p">{`Np-Signature`}</inlineCode>{` header. This allows you to verify that the events were sent by Gateway, not by a third party.`}</p>
    <p>{`Before you can verify signatures, you need to retrieve your endpoint’s secret via `}<a parentName="p" {...{
        "href": "#create-webhook-endpoint"
      }}>{`recreating webhook-endpoint`}</a>{`.`}</p>
    <p>{`Gateway generates a unique secret key for each endpoint. Additionally, if you use multiple endpoints, you must obtain a secret for each one you want to verify signatures on.`}</p>
    <h3>{`Verifying signatures using `}<a parentName="h3" {...{
        "href": "#nodejs-client"
      }}>{`client.js`}</a></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const Client = require('./client')
// Set these in your ENVironment, or enter them here with the actual string
const apiKey = ''
const apiSecret = ''
const client = new Client(apiKey, apiSecret, 'https://api.example.com')

const endpointSecret = 'whsec_...'

// This example uses Express to receive webhooks
const app = require('express')()

// Use body-parser to retrieve the raw body as a buffer
const bodyParser = require('body-parser')

// Match the raw body to content type application/json
app.post('/webhook', bodyParser.raw({type: 'application/json'}), (request, response) => {
  const sig = request.headers['np-signature']

  let event

  try {
    event = client.webhooks.constructEvent(request.body, sig, endpointSecret)
  }
  catch (err) {
    response.status(400).send(\`Webhook Error: \${err.message}\`)
  }

  // Handle the event
  switch (event.type) {
    case 'order':
      const order = event.data
      console.log('Payment status received!')
      break
    // ... handle other event types
    default:
      console.log(\`Unhandled event type \${event.type}\`)
  }

  // Return a response to acknowledge receipt of the event
  response.json({received: true})
})

app.listen(4242, () => console.log('Running on port 4242'))
`}</code></pre>
    <h3>{`Preventing replay attacks`}</h3>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Replay_attack"
      }}>{`replay attack`}</a>{` is when an attacker intercepts a valid payload and its signature, then re-transmits them. To mitigate such attacks, Gateway includes a timestamp in the `}<inlineCode parentName="p">{`Np-Signature`}</inlineCode>{` header. Because this timestamp is part of the signed payload, it is also verified by the signature, so an attacker cannot change the timestamp without invalidating the signature. If the signature is valid but the timestamp is too old, you can have your application reject the payload.`}</p>
    <p>{`Use Network Time Protocol (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Network_Time_Protocol"
      }}>{`NTP`}</a>{`) to ensure that your server’s clock is accurate and synchronizes with the time on Gateway’s servers.`}</p>
    <p>{`Gateway generates the timestamp and signature each time an event is sent to your endpoint. If Gateway retries an event (e.g., your endpoint previously replied with a non-`}<inlineCode parentName="p">{`2xx`}</inlineCode>{` status code), then a new signature and timestamp is generated for the new delivery attempt.`}</p>
    <h3>{`Verifying signatures`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Np-Signature`}</inlineCode>{` header included in each signed event contains a timestamp and one or more signatures. The timestamp is prefixed by `}<inlineCode parentName="p">{`t=`}</inlineCode>{`, and each signature is prefixed by a scheme. Schemes start with `}<inlineCode parentName="p">{`v`}</inlineCode>{`, followed by an integer. Currently, the only valid live signature scheme is `}<inlineCode parentName="p">{`v1`}</inlineCode>{`. To aid with testing, Gateway can sends an additional signature with a fake `}<inlineCode parentName="p">{`v0`}</inlineCode>{` scheme, for test mode events.`}</p>
    <pre><code parentName="pre" {...{}}>{`Np-Signature:
t=1592150977,
v1=a61ba3f33ff3379c4e63ffb26f23ccd6897abe2bc6b22d98165df7eda89cfd36,
v0=c747170c9cfa5d47ae90ea8ed2dc24e1f2e6e9557fcc1ae07bd602f20caa5fdd
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note that newlines have been added for clarity, but a real `}<inlineCode parentName="p">{`Np-Signature`}</inlineCode>{` header is on a single line.`}</p>
    </blockquote>
    <p>{`Gateway generates signatures using a hash-based message authentication code (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Hash-based_message_authentication_code"
      }}>{`HMAC`}</a>{`) with `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/SHA-2"
      }}>{`SHA-256`}</a>{`. To prevent `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Downgrade_attack"
      }}>{`downgrade attacks`}</a>{`, you should ignore all schemes that are not `}<inlineCode parentName="p">{`v1`}</inlineCode>{`.`}</p>
    <p>{`It is possible to have multiple signatures with the same scheme-secret pair.`}</p>
    <h4>{`Step 1: Extract the timestamp and signatures from the header`}</h4>
    <p>{`Split the header, using the `}<inlineCode parentName="p">{`,`}</inlineCode>{` character as the separator, to get a list of elements. Then split each element, using the `}<inlineCode parentName="p">{`=`}</inlineCode>{` character as the separator, to get a prefix and value pair.`}</p>
    <p>{`The value for the prefix `}<inlineCode parentName="p">{`t`}</inlineCode>{` corresponds to the timestamp, and `}<inlineCode parentName="p">{`v1`}</inlineCode>{` corresponds to the signature (or signatures). You can discard all other elements.`}</p>
    <h4>{`Step 2: Prepare the signed_payload string`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`signed_payload`}</inlineCode>{` string is created by concatenating:`}</p>
    <ul>
      <li parentName="ul">{`The timestamp (as a string)`}</li>
      <li parentName="ul">{`The character `}<inlineCode parentName="li">{`.`}</inlineCode></li>
      <li parentName="ul">{`The actual JSON payload (i.e., the request body)`}</li>
    </ul>
    <h4>{`Step 3: Determine the expected signature`}</h4>
    <p>{`Compute an HMAC with the SHA256 hash function. Use the endpoint’s signing secret as the key, and use the `}<inlineCode parentName="p">{`signed_payload`}</inlineCode>{` string as the message.`}</p>
    <h4>{`Step 4: Compare the signatures`}</h4>
    <p>{`Compare the signature (or signatures) in the header to the expected signature. For an equality match, compute the difference between the current timestamp and the received timestamp, then decide if the difference is within your tolerance. We recomend to use a tolerance of five minutes or less.`}</p>
    <p>{`To protect against timing attacks, use a constant-time string comparison to compare the expected signature to each of the received signatures.`}</p>
    <h2>{`Checkout`}</h2>
    <h3>{`Create a Checkout Session (Server-side)`}</h3>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/accounts/:account_id/checkout/sessions`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`mode`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`'payment' or 'payout'`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The name of the item`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].description`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`An arbitrary string attached to the object. Often useful for displaying to users`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].images`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`A list of up to 1 URLs of images for this product, meant to be displayable to the customer`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`[string]`}{` (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Amount for the display item (in smallest common currency units; Ex. kopecks)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Three-letter ISO currency code, in uppercase. Must be a supported currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`success_url`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The URL the customer will be directed to after the payment or subscription creation is successful`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`cancel_url`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The URL the customer will be directed to if they decide to cancel payment and return to your website`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`metadata`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Set of key-value pairs. Up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`hash (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/checkout/sessions',
  {
    method: 'POST',
    parameters: {
      items: [{
        name: 'T-shirt',
        description: 'Comfortable cotton t-shirt',
        images: ['https://example.com/t-shirt.jpg'],
        amount: '150000',
        currency: 'RUB',
      }],
      success_url: 'https://example.com/success',
      cancel_url: 'https://example.com/cancel'
    },
  }
)

{
  "data": {
    "id": "cs_live_mJe7gLiNTQPU4Kh6mEfdU3joafHxXEKYKRduZJJ6k42qE3ne7kTyXVbA",
    "token": "d5274a7f-6fed-4ac4-bf83-027122b39181",
    "items": [
      {
        "name": "T-shirt",
        "description": "Comfortable cotton t-shirt",
        "images": [
          "https://example.com/t-shirt.jpg"
        ],
        "amount": "150000",
        "currency": "RUB"
      }
    ],
    "success_url": "https://example.com/success",
    "cancel_url": "https://example.com/cancel"
  }
}
`}</code></pre>
    <h3>{`Redirect to Checkout (Client-side)`}</h3>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/payment-pages`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`key`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`account publishable key`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`session_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout session id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`session_token`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout session token`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const res = await fetch(\`https://api.example.com/v2/payment-pages\`, {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    key: 'pk_live_iEifWhczCmfvQa78RJzmysyvNpYgXgDPtyxS7RQTfmxA7fuKesdEtQSy',
    session_id: 'cs_live_mJe7gLiNTQPU4Kh6mEfdU3joafHxXEKYKRduZJJ6k42qE3ne7kTyXVbA',
    session_token: 'd5274a7f-6fed-4ac4-bf83-027122b39181',
  }),
})
const {data} = await res.json()
window.location = data.url
`}</code></pre>
    <h3>{`Show Checkout Session payment status (Server-side)`}</h3>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/accounts/:account_id/checkout/sessions/:checkout_session_id/payment`}</inlineCode></p>
    <p>{`Returns payment status for the checkout session or 402 (Payment Required) and "payment not found" error if no payment was created for this checkout session`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/checkout/sessions/cs_live_mJe7gLiNTQPU4Kh6mEfdU3joafHxXEKYKRduZJJ6k42qE3ne7kTyXVbA/payment'
)

// success response
{
  "data": {
    // ... 'Show payment status' data
  }
}

// error response (thrown)
{
  "error": "payment not found"
}
`}</code></pre>
    <h2>{`3D Secure Processing (Special use cases)`}</h2>
    <p>{`! This part has special use cases. Consider `}<a parentName="p" {...{
        "href": "#create-charge-token"
      }}>{`Create charge token`}</a>{` and `}<a parentName="p" {...{
        "href": "#charge-money"
      }}>{`Charge money`}</a>{` with `}<inlineCode parentName="p">{`charge_link`}</inlineCode>{` for simple integration instead.`}</p>
    <h4>{`Step 1 - Get 3ds parameters`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Specify \`challenge_notification_url\` parameter in the \`/charge/token\` method.
// Also skip \`redirect_url\`.

await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge/token',
  {
    method: 'POST',
    parameters: {
      amount: '100',
      challenge_notification_url: 'https://example.com/challenge-notification-url'
    }
  }
)

{
  "data": {
    "token": "ded23543-241c-4c88-8d4a-4cd145269841",
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b"
  }
}

// 2. Specify \`direct_link: true\` in the \`/charge\` method.

await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge',
  {
    method: 'POST',
    parameters: {
      token: 'ded23543-241c-4c88-8d4a-4cd145269841',
      card_info: {
        number: '4242424242424242',
        month: '12',
        year: '2023',
        cvv: '123',
        holder: 'CARD HOLDER'
      },
      direct_link: true
    }
  }
)

// Result will be different depending on the 3ds flow 

// 3ds 1 flow
{
  "data": {
    "url": "...",
    "md": "...",
    "pa_req": "..."
  }
}

// 3ds 2 challenge flow
{
  "data": {
    "url": "...",
    "three_d_s_session_data": "...",
    "creq": "..."
  }
}

// 3ds 2 frictionless flow
{
  "data": {
    "success": true
  }
}
`}</code></pre>
    <p>{`Skip next steps if result is `}<inlineCode parentName="p">{`3ds 2 frictionless flow`}</inlineCode>{`.`}</p>
    <h4>{`Step 2 - Send the customer to a page with a form`}</h4>
    <p>{`The following part are for "3ds 1 flow" and "3ds 2 challenge flow" only.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!--
//  3ds 1 flow
//
//   The \`term_url\` will receive a POST response with \`PaRes\` and \`MD\` once authentication finished
-->

<!doctype html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>3DS</title>
</head>
<body onload="sendpareq.submit();">
  <form action="{{url}}" method="post" name="sendpareq">
    <input type="hidden" name="PaReq" value="{{pa_req}}">
    <input type="hidden" name="MD" value="{{md}}">
    <input type="hidden" name="TermUrl" value="{{term_url}}">
  </form>
</body>
</html>

<!--
// 3ds 2 challenge flow
//
//   The \`challenge_notification_url\` will receive a POST response with \`cres\` and \`threeDSSessionData\` once authentication finished
-->

<!doctype html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>3DS 2</title>
</head>
<body onload="sendcreq.submit();">
<form action="{{url}}" method="post" name="sendcreq">
  <input type="hidden" name="creq" value="{{creq}}">
  <input type="hidden" name="threeDSSessionData" value="{{three_d_s_session_data}}">
</form>
</body>
</html>
`}</code></pre>
    <h4>{`Step 3 - Complete 3ds flow`}</h4>
    <p><inlineCode parentName="p">{`POST https://api.example.com/v2/accounts/:account_id/payment/:order_slug/3ds-complete`}</inlineCode></p>
    <p>{`Make POST request with the received parameters to complete the payment`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// 3ds 1 flow
await client.api(\`/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/3ds-complete\`, {
  method: 'POST',
  parameters: {
    pa_res: PaRes,
    md: MD,
  }
})

// 3ds 2 challenge flow
await client.api(\`/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/3ds-complete\`, {
  method: 'POST',
  parameters: {
    cres: cres,
    three_d_s_session_data: threeDSSessionData,
  }
})

// Result
{
  "data": {
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b",
  }
}
`}</code></pre>
    <h2>{`List rates`}</h2>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/accounts/:account_id/rates/latest`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`GET query params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`base`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Base currency. Three-letter ISO currency code, in uppercase. Must be a supported currency. Default is "EUR"`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Amount to show rates referring to (in smallest common currency units; Ex. kopecks). Default is "100" (€1)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/rates/latest'
)

{
  "data": {
    "base": "EUR",
    "date": "2020-07-24",
    "rates": {
      "AUD": "1.6376",
      "BGN": "1.9558",
      "BRL": "6.0777",
      "CAD": "1.5578",
      "CHF": "1.073",
      "CNY": "8.1453",
      "CZK": "26.268",
      "DKK": "7.4438",
      "GBP": "0.90985",
      "HKD": "8.9978",
      "HRK": "7.517",
      "HUF": "346.98",
      "IDR": "16982",
      "ILS": "3.9642",
      "INR": "86.866",
      "ISK": "157.8",
      "JPY": "123.36",
      "KRW": "1396.83",
      "MXN": "26.0804",
      "MYR": "4.9502",
      "NOK": "10.6953",
      "NZD": "1.7506",
      "PHP": "57.316",
      "PLN": "4.4046",
      "RON": "4.8325",
      "RUB": "83.3938",
      "SEK": "10.269",
      "SGD": "1.6083",
      "THB": "36.821",
      "TRY": "7.9496",
      "USD": "1.1608",
      "ZAR": "19.435"
    }
  }
}
`}</code></pre>
    <h2>{`Get current time`}</h2>
    <p>{`Get the API server time.`}</p>
    <p>{`This endpoint doesn’t require authentication.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.example.com/v2/time`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await client.api(
  '/v2/time'
)

{
  "data": {
    "iso": "2020-01-03T03:21:41.647Z",
    "epoch": 1578021701
  }
}
`}</code></pre>
    <h2>{`Testing`}</h2>
    <h3>{`Basic test card numbers`}</h3>
    <p>{`Use any of the following test card numbers, a valid expiration date in the future, and any random CVC number, to create a successful payment.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`number`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`BRAND`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`CVC`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`DATE`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4242`}{` `}{`4242`}{` `}{`4242`}{` `}{`4242`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Visa`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Any 3 digits`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Any future date`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Testing for specific responses and errors`}</h3>
    <p>{`The following test cards can be used to create payments that produce specific responses—useful for testing different scenarios and error codes.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`number`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4000`}{` `}{`0000`}{` `}{`0000`}{` `}{`0119`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge is declined with a 17 state code (Failure of the issuer or payment system).`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Node.js Client`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// client.js
const fetch = require('node-fetch')
const crypto = require('crypto')
const queryString = require('query-string')

async function handleErrors(response) {
  if (!response.ok) {
    let json
    try {
      json = await response.json()
    } catch(e) {
      const error = new Error(response.statusText)
      error.response = response
      throw error
    }
    const error = new Error(json.error)
    error.response = response
    error.json = json
    throw error
  }
  return response
}

class Client {
  constructor(apiKey, apiSecret, baseUrl) {
    this.apiKey = apiKey
    this.apiSecret = apiSecret
    this.baseUrl = baseUrl
    this.errors = require('./client-errors')
    this.webhooks = require('./client-webhooks')
  }

  accessToken(method, path, querySorted, body) {
    //get unix time in seconds
    const timestamp = Math.floor(Date.now() / 1000)

    const version = '2019-12-22'

    const message = timestamp + method.toUpperCase() + path + querySorted + (body || '')

    //create a hexedecimal encoded SHA256 signature of the message
    const signature = crypto.createHmac('sha256', this.apiSecret).update(message).digest('hex')

    return [version, timestamp, this.apiKey, signature].join('.')
  }

  async api(path, {method = 'GET', parameters} = {}) {
    const params = { ...parameters }

    const queryInsteadOfBody = method === 'GET' || method === 'HEAD'
    let query = ''
    let body = undefined
    if (queryInsteadOfBody) {
      // Stringify an object into a query string and sorting the keys.
      const str = queryString.stringify(params)
      if (str) {
        query = \`?\${str}\`
      }
    } else {
      body = JSON.stringify(params)
    }

    const accessToken = this.accessToken(method, path, query, body)

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': \`NP-ACCESS-TOKEN \${accessToken}\`,
    }

    const response = await fetch(this.baseUrl + path + query, {
      method,
      headers,
      body,
    })
    const res = await handleErrors(response)
    return await res.json()
  }
}

Client.errors = require('./client-errors')
Client.webhooks = require('./client-webhooks')

module.exports = Client
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// client-errors.js

/**
 * ClientError is the base error from which all other more specific Client errors derive.
 * Specifically for errors returned from Client's REST API.
 */
class ClientError extends Error {
  constructor(raw = {}) {
    super(raw.message)
    this.type = this.constructor.name

    this.raw = raw
    this.detail = raw.detail
    this.message = raw.message
  }
}

/**
 * SignatureVerificationError is raised when the signature verification for a
 * webhook fails
 */
class ClientSignatureVerificationError extends ClientError {}

module.exports.ClientError = ClientError
module.exports.ClientSignatureVerificationError = ClientSignatureVerificationError
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// client-webhooks.js
const crypto = require('crypto')

const {ClientError, ClientSignatureVerificationError} = require('./client-errors')

const Webhook = {
  DEFAULT_TOLERANCE: 300, // 5 minutes

  constructEvent(payload, header, secret, tolerance) {
    this.signature.verifyHeader(
      payload,
      header,
      secret,
      tolerance || Webhook.DEFAULT_TOLERANCE
    )

    const jsonPayload = JSON.parse(payload)
    return jsonPayload
  },

  /**
   * Generates a header to be used for webhook mocking
   *
   * @typedef {object} opts
   * @property {number} timestamp - Timestamp of the header. Defaults to Date.now()
   * @property {string} payload - JSON stringified payload object, containing the 'id' and 'object' parameters
   * @property {string} secret - Client webhook secret 'whsec_...'
   * @property {string} scheme - Version of API to hit. Defaults to 'v1'.
   * @property {string} signature - Computed webhook signature
   */
  generateTestHeaderString: function(opts) {
    if (!opts) {
      throw new ClientError({
        message: 'Options are required',
      })
    }

    opts.timestamp =
      Math.floor(opts.timestamp) || Math.floor(Date.now() / 1000)
    opts.scheme = opts.scheme || signature.EXPECTED_SCHEME

    opts.signature =
      opts.signature ||
      signature._computeSignature(
        opts.timestamp + '.' + opts.payload,
        opts.secret
      )

    const generatedHeader = [
      't=' + opts.timestamp,
      opts.scheme + '=' + opts.signature,
    ].join(',')

    return generatedHeader
  },
}

const signature = {
  EXPECTED_SCHEME: 'v1',

  _computeSignature: (payload, secret) => {
    return crypto
      .createHmac('sha256', secret)
      .update(payload, 'utf8')
      .digest('hex')
  },

  verifyHeader(payload, header, secret, tolerance) {
    payload = Buffer.isBuffer(payload) ? payload.toString('utf8') : payload

    // Express's type for \`Request#headers\` is \`string | []string\`
    // which is because the \`set-cookie\` header is an array,
    // but no other headers are an array (docs: https://nodejs.org/api/http.html#http_message_headers)
    // (Express's Request class is an extension of http.IncomingMessage, and doesn't appear to be relevantly modified: https://github.com/expressjs/express/blob/master/lib/request.js#L31)
    if (Array.isArray(header)) {
      throw new Error(
        'Unexpected: An array was passed as a header, which should not be possible for the Np-Signature header.'
      )
    }

    header = Buffer.isBuffer(header) ? header.toString('utf8') : header

    const details = parseHeader(header, this.EXPECTED_SCHEME)

    if (!details || details.timestamp === -1) {
      throw new ClientSignatureVerificationError({
        message: 'Unable to extract timestamp and signatures from header',
        detail: {
          header,
          payload,
        },
      })
    }

    if (!details.signatures.length) {
      throw new ClientSignatureVerificationError({
        message: 'No signatures found with expected scheme',
        detail: {
          header,
          payload,
        },
      })
    }

    const expectedSignature = this._computeSignature(
      \`\${details.timestamp}.\${payload}\`,
      secret
    )

    const signatureFound = !!details.signatures.filter(
      secureCompare.bind(null, expectedSignature)
    ).length

    if (!signatureFound) {
      throw new ClientSignatureVerificationError({
        message:
          'No signatures found matching the expected signature for payload.' +
          ' Are you passing the raw request body you received from Client?',
        detail: {
          header,
          payload,
        },
      })
    }

    const timestampAge = Math.floor(Date.now() / 1000) - details.timestamp

    if (tolerance > 0 && timestampAge > tolerance) {
      throw new ClientSignatureVerificationError({
        message: 'Timestamp outside the tolerance zone',
        detail: {
          header,
          payload,
        },
      })
    }

    return true
  },
}

function parseHeader(header, scheme) {
  if (typeof header !== 'string') {
    return null
  }

  return header.split(',').reduce(
    (accum, item) => {
      const kv = item.split('=')

      if (kv[0] === 't') {
        accum.timestamp = kv[1]
      }

      if (kv[0] === scheme) {
        accum.signatures.push(kv[1])
      }

      return accum
    },
    {
      timestamp: -1,
      signatures: [],
    }
  )
}

Webhook.signature = signature

/**
 * Secure compare, from https://github.com/freewil/scmp
 */
const secureCompare = (a, b) => {
  a = Buffer.from(a)
  b = Buffer.from(b)

  // return early here if buffer lengths are not equal since timingSafeEqual
  // will throw if buffer lengths are not equal
  if (a.length !== b.length) {
    return false
  }

  // use crypto.timingSafeEqual if available (since Node.js v6.6.0),
  // otherwise use our own scmp-internal function.
  if (crypto.timingSafeEqual) {
    return crypto.timingSafeEqual(a, b)
  }

  const len = a.length
  let result = 0

  for (let i = 0; i < len; ++i) {
    result |= a[i] ^ b[i]
  }
  return result === 0
}

module.exports = Webhook
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      